export const applicationLink = '/processo'
export const cblLink = 'https://www.challengebasedlearning.org/'
export const appleRefLink = 'https://developer.apple.com/learn/experts/'
export const appleNewsLink =
  'https://www.apple.com/br/newsroom/2021/05/coders-designers-and-entrepreneurs-thrive-thanks-to-apple-developer-academy/'
export const moreAppsLink =
  'https://medium.com/apple-developer-academy-pucpr/jogos-hipercasuais-da-apple-developer-academy-pucpr-edição-2022-6d9b6bf32158'
export const mediumLink = 'https://medium.com/apple-developer-academy-pucpr'
export const twitterLink = 'https://twitter.com/adapucpr'
export const instagramLink = 'https://www.instagram.com/adapucpr/'
export const youtubeLink = 'https://www.youtube.com/channel/UCd3LlWweNaaw0DKrdUT54wA'
export const facebookLink = 'https://www.facebook.com/adapucpr/'

export const email = 'appledeveloperacademy@pucpr.br'

export const students = [
  { name: 'Alis Frentzel', linkedIn: 'https://www.linkedin.com/in/alis-frentzel-231923162/' },
  { name: 'Ana C.S. Costa', linkedIn: 'https://www.linkedin.com/in/ana-carolina-costa-a66bb5180/' },
  { name: 'Caroline Revay', linkedIn: 'https://www.linkedin.com/in/caroline-revay-833b751a1/' },
  { name: 'Mariana Florencio', linkedIn: 'https://www.linkedin.com/in/mariana-florencio/' },
  { name: 'Raquel Zocoler', linkedIn: 'https://www.linkedin.com/in/raquel-zocoler/' },
  { name: 'Tacila Evangelista', linkedIn: 'https://www.linkedin.com/in/tacilaevangelista/' },
]

export const application = {
  open: true,
  applicationsOpen: false,
  endDate: '29/09/24',
  forClass: '7',
}
